<template>
  <div class="present">
    <dir class="yiqi" style="margin: 0; padding: 0">
      <div>
        <div @click="num = 0" :class="{ colors: num == 0 }">
          <i class="el-icon-s-order"></i>封面
        </div>
        <!-- <div @click="zouyibu" :class="{ colors: num == 1 }">
          <i class="el-icon-s-data"></i> 企业申报书
        </div> -->
        <div @click="zouyibu" :class="{ colors: num == 1 && isShow == true }">
          <i class="el-icon-s-data"></i> 企业申报书
        </div>
        <div @click="zouyibus" :class="{ colors: num == 1 && isShow == false }">
          <i class="el-icon-s-data"></i> 推荐意见表
        </div>
        <div @click="num = 3" :class="{ colors: num == 3 }">
          <i class="el-icon-s-data"></i>上传申报附件
        </div>
        <div @click="num = 4" :class="{ colors: num == 4 }">
          <i class="el-icon-s-data"></i>提交申报材料
        </div>
      </div>
      <div class="main">
        <div v-if="num == 0">
          <div class="fengmian" id="printTest" ref="imageWrapper">
            <div>
              <div>2022年中国产学研创新示范企业（试点）申报书</div>
            </div>
            <div style="width: 80%; padding: 0 180px; box-sizing: border-box">
              <!-- <div>
                <div>申 报 人：</div>
                <input
                  type="text"
                  style="width: 100%"
                  v-if="iss"
                  v-model="fengmian.cover_name"
                />
                <div
                  style="
                    text-align: left;
                    border-bottom: 1px solid #999;
                    width: 100%;
                  "
                  v-else
                  v-html="fengmian.cover_name"
                ></div>
              </div> -->
              <div>
                <div style="margin: 40px 0">申报单位:（加盖公章）</div>
                <input
                  type="text"
                  style="width: 100%"
                  v-if="iss"
                  v-model="fengmian.cover_unit"
                />
                <div
                  style="
                    text-align: left;
                    border-bottom: 1px solid #999;
                    width: 100%;
                  "
                  v-else
                  v-html="fengmian.cover_unit"
                ></div>
              </div>
              <div>
                <div>申报时间：</div>
                <input
                  type="text"
                  style="width: 100%"
                  v-if="iss"
                  v-model="fengmian.cover_time"
                />
                <div
                  style="
                    text-align: left;
                    border-bottom: 1px solid #999;
                    width: 100%;
                  "
                  v-else
                  v-html="fengmian.cover_time"
                ></div>
              </div>
            </div>
          </div>
          <div class="feng_btn">
            <el-button @click="xiaxia" type="primary">保存</el-button>
            <el-button @click="xiaxiadayin" type="primary">下载</el-button>
          </div>
        </div>
        <div v-if="num == 1" class="shenMain">
          <div class="tabls" v-if="isShow">
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <div>
                <h3 class="zzzzzz">中国产学研创新示范企业（试点）申报书</h3>
              </div>
              <table border="1" cellpading="0" cellspace="0">
                <tbody>
                  <tr>
                    <td>企业名称</td>
                    <td colspan="7">
                      <input
                        style="
                          width: 100%;

                          box-sizing: border-box;
                          padding: 0 10px;
                          font-size: 28px;
                        "
                        v-model="xinxi.unit_name"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>地址</td>
                    <td colspan="7">
                      <input
                        style="
                          width: 100%;

                          box-sizing: border-box;
                          padding: 0 10px;
                          font-size: 28px;
                        "
                        v-model="xinxi.unit_address"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>法定代表人</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.legal_name"
                      />
                    </td>
                    <td>电话</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.mobile_tel"
                      />
                    </td>
                    <td>邮编</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.post"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>联系人</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.user_name"
                      />
                    </td>
                    <td>职务</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.job"
                      />
                    </td>
                    <td>手机</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.mobile"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>微信号</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.wechat_number"
                      />
                    </td>
                    <td>传真</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.fax"
                      />
                    </td>
                    <td>E-mail</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.email"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      企业名称
                      <div>所属行业</div>
                    </td>
                    <td colspan="7">
                      <input
                        style="
                          width: 100%;

                          box-sizing: border-box;
                          padding: 0 10px;
                          font-size: 28px;
                        "
                        v-model="xinxi.domain"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <div>
                        职工总数：<span
                          ><input
                            type="text"
                            style="width: 20%; box-sizing: border-box"
                            v-model="xinxi.staff_number"
                        /></span>
                        人
                      </div>
                    </td>
                    <td colspan="3">
                      <div>
                        大专以上科研人数：<span
                          ><input
                            type="text"
                            style="width: 20%; box-sizing: border-box"
                            v-model="xinxi.staff_number1"
                        /></span>
                        人
                      </div>
                    </td>
                    <td colspan="3">
                      <div>
                        从事研发科研人数：<span
                          ><input
                            type="text"
                            style="width: 20%; box-sizing: border-box"
                            v-model="xinxi.staff_number2"
                        /></span>
                        人
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>类型</td>
                    <td colspan="7">
                      <div class="xuanze">
                        <div v-for="(item, index) in select" :key="index">
                          <input
                            type="checkbox"
                            :checked="!shuzu ? '' : shuzu.indexOf(item.id) >= 0"
                            name="checkboxinput"
                            class="input-checkbox"
                            @click="checkedOne(item.id)"
                          />
                          <span>{{ item.name }}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">是否进入高新区或国家经开区</td>
                    <td>
                      <div class="jingkai">
                        <div>
                          <input
                            type="checkbox"
                            name="checkboxinput"
                            class="input-checkbox"
                            @change="changeXuan('1')"
                            :checked="shuzus.indexOf('1') >= 0"
                          />是
                        </div>
                        <div
                          style="width: 100%; border-bottom: 1px solid"
                        ></div>
                        <div>
                          <input
                            type="checkbox"
                            name="checkboxinput"
                            class="input-checkbox"
                            @change="changeXuan('2')"
                            :checked="shuzus.indexOf('2') >= 0"
                          />否
                        </div>
                      </div>
                    </td>
                    <td colspan="4">
                      <div class="jingkai">
                        <div style="text-align: left">
                          <!-- <input
                            type="checkbox"
                            name="checkboxinput"
                            class="input-checkbox"
                          /> -->
                          国家高新区或经开区名称：
                          <input
                            type="text"
                            style="
                              width: 58%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                            v-model="xinxi.new_name"
                          />
                        </div>
                        <div style="text-align: left">
                          <!-- <input
                            type="checkbox"
                            name="checkboxinput"
                            class="input-checkbox"
                          /> -->
                          省市开发区名称：
                          <input
                            type="text"
                            style="
                              width: 71%;
                              padding: 0 10px;
                              box-sizing: border-box;
                            "
                            v-model="xinxi.new_province_name"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">企业，产品获奖情况</td>

                    <td colspan="5" style="text-align: left; padding: 0 10px">
                      总数：
                      <input
                        type="text"
                        style="
                          width: 10%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.unit_award"
                      />
                      个（详细内容请作为附件材料），另附件
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">获得国家专利情况</td>

                    <td colspan="5" style="text-align: left; padding: 0 10px">
                      总数：
                      <input
                        type="text"
                        style="
                          width: 10%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.unit_patent"
                      />
                      个（详细内容请作为附件材料），另附件
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" style="text-align: left; padding: 0 20px">
                      <div>
                        研究开发经费：<span
                          ><input
                            type="text"
                            style="width: 20%; box-sizing: border-box"
                            v-model="xinxi.expenditure"
                        /></span>
                        万元
                      </div>
                    </td>

                    <td colspan="4" style="text-align: left; padding: 0 20px">
                      <div>
                        研发经费占上年总销售额的比例：<span
                          ><input
                            type="text"
                            style="width: 20%; box-sizing: border-box"
                            v-model="xinxi.expenditure_ratio"
                        /></span>
                        %
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div>企业上年财务状况</div>
                      <div>单位：（万元）</div>
                    </td>
                    <td colspan="2">
                      <div class="jingkai">
                        <div>
                          资产总额：<input
                            type="text"
                            style="width: 40%; box-sizing: border-box"
                            v-model="xinxi.asset_attributes"
                          />
                        </div>
                        <div
                          style="width: 100%; border-bottom: 1px solid"
                        ></div>
                        <div>
                          缴税总额：<input
                            type="text"
                            style="width: 40%; box-sizing: border-box"
                            v-model="xinxi.tax_attributes"
                          />
                        </div>
                      </div>
                    </td>
                    <td colspan="2">
                      <div class="jingkai">
                        <div>
                          固定资产：<input
                            type="text"
                            style="width: 40%; box-sizing: border-box"
                            v-model="xinxi.fixed_assets"
                          />
                        </div>
                        <div
                          style="width: 100%; border-bottom: 1px solid"
                        ></div>
                        <div>
                          税后例利润：<input
                            type="text"
                            style="width: 40%; box-sizing: border-box"
                            v-model="xinxi.profit_after_tax"
                          />
                        </div>
                      </div>
                    </td>
                    <td colspan="2">
                      <div class="jingkai">
                        <div>
                          总产值：<input
                            type="text"
                            style="width: 40%; box-sizing: border-box"
                            v-model="xinxi.total_price"
                          />
                        </div>
                        <div
                          style="width: 100%; border-bottom: 1px solid"
                        ></div>
                        <div>
                          销售收入：<input
                            type="text"
                            style="width: 40%; box-sizing: border-box"
                            v-model="xinxi.sales_revenue"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" style="text-align: left; padding: 10px">
                      <div class="chanxue">
                        <div>产学研合作项目实施情况：</div>
                        <div>（详细内容请作为附件材料）</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" style="text-align: left; padding: 10px">
                      <div class="chanxue">
                        <div>产学研合作概况：</div>
                        <div>（详细内容请作为附件材料）</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" style="text-align: left; padding: 10px">
                      <div class="chanxue">
                        <div>企业主要成果：</div>
                        <div>（详细内容请作为附件材料）</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" style="text-align: left; padding: 10px">
                      <div class="chanxue">
                        <div>产学研合作成功案例：</div>
                        <div>（详细内容请作为附件材料）</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" height="220px">
                      <div class="biaoge">
                        <div class="biaogesss">
                          <div>法人代表签字：</div>
                        </div>
                        <div>
                          <div></div>
                          <div>
                            <div>企业公章:</div>
                            <div class="nian">
                              <div>年</div>
                              <div>月</div>
                              <div>日</div>
                            </div>
                          </div>
                        </div>
                        <div class="faren"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="feng_btn">
              <el-button @click="clickXia" type="primary">保存</el-button>
              <el-button @click="clickXias" type="primary">下载</el-button>
            </div>
          </div>
          <div class="tabls" v-else>
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <div>
                <h3 class="zzzzzz">推荐意见表</h3>
              </div>
              <table border="1" cellpading="0" cellspace="0">
                <tbody>
                  <tr>
                    <td>推荐单位名称</td>
                    <td colspan="7">
                      <input
                        style="
                          width: 100%;

                          box-sizing: border-box;
                          padding: 0 10px;
                          font-size: 28px;
                        "
                        v-model="xinxi1.recommended_units"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>地址</td>
                    <td colspan="7">
                      <input
                        style="
                          width: 100%;

                          box-sizing: border-box;
                          padding: 0 10px;
                          font-size: 28px;
                        "
                        v-model="xinxi1.recommended_address"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>联系人</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi1.recommended_smartphone"
                      />
                    </td>
                    <td>职务</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi1.recommended_battle_field"
                      />
                    </td>
                    <td>手机</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi1.recommended_mobile"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" height="320px">
                      <div class="bis">
                        <div>
                          <div>推荐单位意见：</div>
                        </div>
                        <el-input
                          v-if="iss"
                          type="textarea"
                          v-model="xinxi1.recommended_content"
                          cols="40"
                          style="font-size: 24px"
                          rows="8"
                        ></el-input>

                        <div
                          style="
                            text-align: left;
                            padding: 0 10px;
                            box-sizing: border-box;
                          "
                          v-else
                          v-html="xinxi1.recommended_content"
                        ></div>
                        <div>
                          <div></div>
                          <div>
                            <div>推荐单位（公章）:</div>
                            <div class="nian">
                              <div>年</div>
                              <div>月</div>
                              <div>日</div>
                            </div>
                          </div>
                        </div>
                        <div class="faren"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="feng_btn">
              <el-button @click="xiayibu1" type="primary">保存</el-button>
              <el-button @click="clickXiase" type="primary">下载</el-button>
            </div>
          </div>
        </div>

        <div v-else-if="num == 3">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 20px 0;
            "
          >
            <el-alert
              title="提示：添加完文件后，请点击上传按钮。"
              type="success"
              style="width: 60%; font-size: 22px"
            ></el-alert>
          </div>
          <div class="shangchuan1">
            <div class="box">
              <div>产学研合作概况</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory1)"
                    style="width: 113px; height: 45px"
                    >{{ obj.accessory1 == null ? "空" : "预览" }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile1"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="box">
              <div>产学研合作项目实施情况</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory2)"
                    style="width: 113px; height: 45px"
                    >{{ obj.accessory2 == null ? "空" : "预览" }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile2"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="box">
              <div>企业主要成果</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory3)"
                    style="width: 113px; height: 45px"
                    >{{ obj.accessory3 == null ? "空" : "预览" }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile3"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="box">
              <div>产学研合作成功案例</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory4)"
                    style="width: 113px; height: 45px"
                    >{{ obj.accessory4 == null ? "空" : "预览" }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile4"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="box">
              <div>营业执照复印件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory5)"
                    style="width: 113px; height: 45px"
                    >{{ obj.accessory5 == null ? "空" : "预览" }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile5"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="box">
              <div>获自主知识产权情况</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory6)"
                    style="width: 113px; height: 45px"
                    >{{ obj.accessory6 == null ? "空" : "预览" }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile6"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="box">
              <div>获得奖励的情况</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory7)"
                    style="width: 113px; height: 45px"
                    >{{ obj.accessory7 == null ? "空" : "预览" }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile7"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="box">
              <div>产学研合作协议、研发合作协议复印件（首末页）</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory8)"
                    style="width: 113px; height: 45px"
                    >{{ obj.accessory8 == null ? "空" : "预览" }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile8"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="box">
              <div>产学研合作成果列入政府资助项目材料列举</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory9)"
                    style="width: 113px; height: 45px"
                    >{{ obj.accessory9 == null ? "空" : "预览" }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile9"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="box">
              <div>上年度财务审计报告 (主要页)</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory10)"
                    style="width: 113px; height: 45px"
                    >{{ obj.accessory10 == null ? "空" : "预览" }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile10"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="box">
              <div>企业在相关媒体的宣传报道及活动简报</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory11)"
                    style="width: 113px; height: 45px"
                    >{{ obj.accessory11 == null ? "空" : "预览" }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile11"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="box">
              <div>其他相关证明</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory12)"
                    style="width: 113px; height: 45px"
                    >{{ obj.accessory12 == null ? "空" : "预览" }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile12"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
              </div>
            </div>
          </div>
          <div class="feng_btn">
            <el-button @click="wancheng" type="primary">上传</el-button>
          </div>
        </div>
        <div class="zuihou" v-else-if="num == 4">
          <dir>
            <div>
              <h3>
                本人知悉中国产学研创新示范企业（试点）申报条件，了解有关要求
              </h3>
            </div>
            <div>1.遵守国家法律法规、社会道德风尚和科研诚信要求。</div>
            <div>
              2.填写的材料真实有效，不存在弄虚作假、侵占他人成果等行为，本人对其客观性和真实性负责。
            </div>
            <div>
              3.填写的材料符合国家保密法律法规和奖项保密要求，不涉及国家秘密，可用于评审。
            </div>
            <div style="color: red">
              <input
                class="chea"
                name="Fruit"
                type="checkbox"
                v-model="showss"
                value=""
              />本人对以上内容及全部申报附件材料的客观性和真实性负责
            </div>
            <div>
              <el-button @click="wancheng1" type="primary">确认提交</el-button>
              <el-button @click="daochus" type="primary">打包下载</el-button>
            </div>
          </dir>
        </div>
      </div>
    </dir>

    <el-dialog
      :show-close="false"
      :visible.sync="centerDialogVisible"
      width="700px"
      center
    >
      <div class="tijiaos">
        <div>提交成功</div>
        <div>
          请于2022年9月30日前将纸质申报材料快递至中国产学研合作促进会办公室
        </div>
        <div>地址：北京海淀区西三环北路89号外文大厦A座803</div>
        <div>电话：(010)68985790</div>
        <div @click="centerDialogVisible = false">返回</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  upload_file,
  awardDetails,
  craftsmanship_spirit,
  award_find,
  user_file,
  upload_pdf,
  file_download,
  awards,
  submit_audit,
} from "../axios/api";
export default {
  data() {
    return {
      shuzus: [],
      iss: true,
      showss: false,
      fengmian: { cover_name: "", cover_unit: "", cover_time: "" },
      obj: {
        token: sessionStorage.getItem("Tokens"),

        type: "3",
        accessory1: null,
        accessory2: null,
        accessory3: null,
        accessory4: null,
        accessory5: null,
        accessory6: null,
        accessory7: null,
        accessory8: null,
        accessory9: null,
        accessory10: null,
        accessory11: null,
        accessory12: null,
      },
      centerDialogVisible: false,
      pdfUrl: "",
      url: "",
      id: "",
      is: true,
      textarea: "",
      fileList: [],
      normal: [],
      user_name: "",
      num: 0,
      // isShow: true,
      isShow: true,
      xinxi: {},
      xinxi1: {},
      vHtml: "",
      isShows: 1,
      isShowss: 1,
      user: { name: "", age: "" },
      select: [
        { id: 1, name: "国有企业" },
        { id: 2, name: "集体企业" },
        { id: 3, name: "私营企业" },
        { id: 4, name: "股份有限公司" },
        { id: 5, name: "联营企业" },
        { id: 6, name: "股份合作企业" },
        { id: 7, name: "有限责任公司" },
        { id: 8, name: "外商投资公司" },
        { id: 9, name: "港、奥、台商投资公司" },
        { id: 10, name: "其他企业" },
      ],
      shuzu: [],
      user_split_id: "",
    };
  },
  created() {
    this.user_chile_details();
  },
  components: {},

  methods: {
    changeXuan(id) {
      let idIndex = this.shuzus.indexOf(id);
      if (idIndex >= 0) {
        this.shuzus = [];
      } else {
        if (this.shuzus == "") {
          this.shuzus = [];
        }
        this.shuzus = [];
        this.shuzus.push(id);
        this.xinxi.is_new = id;
      }
    },
    checkedOne(id) {
      console.log(id);
      let idIndex = this.shuzu.indexOf(id);
      if (idIndex >= 0) {
        // 如果已经包含了该id, 则去除(单选按钮由选中变为非选中状态)
        this.shuzu.splice(idIndex, 1);
      } else {
        // 选中该checkbox
        console.log(this.shuzu);
        if (this.shuzu == "") {
          this.shuzu = [];
        }
        this.shuzu.push(id);
      }
      this.xinxi.type_data = this.shuzu;
      console.log(this.xinxi.type_data);
    },
    zouyibu() {
      this.isShow = true;
      this.num = 1;
    },
    zouyibus() {
      this.isShow = false;
      this.num = 1;
    },
    user_chile_details() {
      let params = {
        token: sessionStorage.getItem("Tokens"),
      };
      awardDetails(params).then((res) => {
        console.log(res.data.data);
        this.user_split_id = res.data.data.id;
        this.fengmian.cover_name = res.data.data.cover_name;
        this.fengmian.cover_unit = res.data.data.cover_unit;
        this.fengmian.cover_time = res.data.data.cover_time;
        this.obj = {
          token: sessionStorage.getItem("Tokens"),
          type: "3",
          accessory1: res.data.data.accessory1,
          accessory2: res.data.data.accessory2,
          accessory3: res.data.data.accessory3,
          accessory4: res.data.data.accessory4,
          accessory5: res.data.data.accessory5,
          accessory6: res.data.data.accessory6,
          accessory7: res.data.data.accessory7,
          accessory8: res.data.data.accessory8,
          accessory9: res.data.data.accessory9,
          accessory10: res.data.data.accessory10,
          accessory11: res.data.data.accessory11,
          accessory12: res.data.data.accessory12,
        };
        this.shuzu = res.data.data.type_data || [];
        this.shuzus = [];
        this.shuzus.push(res.data.data.is_new);
        console.log(this.shuzus);
        this.xinxi = {
          token: sessionStorage.getItem("Tokens"),
          unit_name: res.data.data.unit_name,
          unit_address: res.data.data.unit_address,
          legal_name: res.data.data.legal_name,
          mobile_tel: res.data.data.mobile_tel,
          post: res.data.data.post,
          job: res.data.data.job,
          mobile: res.data.data.mobile,
          wechat_number: res.data.data.wechat_number,
          fax: res.data.data.fax,
          domain: res.data.data.domain,
          user_name: res.data.data.user_name,
          staff_number: res.data.data.staff_number,
          staff_number1: res.data.data.staff_number1,
          staff_number2: res.data.data.staff_number2,
          unit_award: res.data.data.unit_award,
          unit_patent: res.data.data.unit_patent,
          expenditure: res.data.data.expenditure,
          new_name: res.data.data.new_name,
          new_province_name: res.data.data.new_province_name,
          email: res.data.data.email,
          expenditure_ratio: res.data.data.expenditure_ratio,
          asset_attributes: res.data.data.asset_attributes,
          fixed_assets: res.data.data.fixed_assets,
          total_price: res.data.data.total_price,
          tax_attributes: res.data.data.tax_attributes,
          profit_after_tax: res.data.data.profit_after_tax,
          sales_revenue: res.data.data.sales_revenue,
          is_new: res.data.data.is_new,
          type_data: res.data.data.type_data || [],
        };
        this.xinxi1 = {
          token: sessionStorage.getItem("Tokens"),

          recommended_units: res.data.data.recommended_units,
          recommended_address: res.data.data.recommended_address,
          recommended_smartphone: res.data.data.recommended_smartphone,
          recommended_battle_field: res.data.data.recommended_battle_field,
          recommended_mobile: res.data.data.recommended_mobile,
          recommended_content: res.data.data.recommended_content,
        };
      });
    },

    xiaxiadayin() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "产学研创新示范企业",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Tokens"));
          upload_pdf(form).then((res) => {
            console.log(res);
            window.open(
              `/common/2022/download_file?path=${res.data.url}&file_name=首页`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    kan(e) {
      if (!e) {
        alert("请上传pdf在预览");
        return;
      }
      window.open(e, "_blank");
    },
    uploadFile(item) {
      //我的项目要求formdata上传数据
      console.log(item.file);
      const extension = item.file.name.split(".")[1] === "pdf";
      const extension2 = item.file.name.split(".")[1] === "PDF";
      if (!extension && !extension2) {
        alert("上传模板只能是 PDF格式!");
        return;
      }
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Tokens"));
      upload_file(form).then((res) => {
        console.log(res);
        console.log(this.fileList);
        if (res.data.code == 200) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          // this.arr.forEach((item, index) => {
          //   if (item.category == 1) {
          //   }
          // });
          this.url = res.data.url;
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile1(item) {
      //我的项目要求formdata上传数据
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.warning("上传模板只能是pdf格式!");
        return false;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Tokens"));

      upload_file(form).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.obj.accessory1 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile2(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.warning("上传模板只能是pdf格式!");
        return false;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Tokens"));

      upload_file(form).then((res) => {
        console.log(res);
        console.log(this.fileList);
        if (res.data.code == 200) {
          this.obj.accessory2 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile3(item) {
      //我的项目要求formdata上传数据
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.warning("上传模板只能是pdf格式!");
        return false;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Tokens"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory3 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    xiazai() {
      let p = {
        id: 6,
      };
      award_find(p).then((res) => {
        console.log(res);
        window.location.href = res.data.data.file_url;
      });
    },
    uploadFile4(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.warning("上传模板只能是pdf格式!");
        return false;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Tokens"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory4 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile5(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.warning("上传模板只能是pdf格式!");
        return false;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Tokens"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory5 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile6(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.warning("上传模板只能是pdf格式!");
        return false;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Tokens"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory6 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile7(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.warning("上传模板只能是pdf格式!");
        return false;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Tokens"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory7 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile8(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.warning("上传模板只能是pdf格式!");
        return false;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Tokens"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory8 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    xiaxia() {
      this.iss = !this.iss;
      setTimeout(() => {
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "产学研创新示范企业-首页",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Tokens"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 1,
              token: sessionStorage.getItem("Tokens"),
              type_name: "产学研创新示范企业-首页",
            };
            user_file(params).then((res) => {
              console.log(res);
              let data = {
                ...this.fengmian,
                type: 1,
                token: sessionStorage.getItem("Tokens"),
                id: this.user_split_id,
              };
              console.log(data);
              awards(data).then((res) => {
                if (res.data.code == 200) {
                  this.$message({
                    message: "保存成功",
                    type: "success",
                  });
                } else {
                  this.$message.error(res.data.msg);
                }
              });
            });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    uploadFile9(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.warning("上传模板只能是pdf格式!");
        return false;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Tokens"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory9 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile10(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.warning("上传模板只能是pdf格式!");
        return false;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Tokens"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory10 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile11(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.warning("上传模板只能是pdf格式!");
        return false;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Tokens"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory11 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile12(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.warning("上传模板只能是pdf格式!");
        return false;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Tokens"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory12 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    handlePictureCardPreview(file) {
      console.log(file);
      // window.open(
      //   `https://view.officeapps.live.com/op/view.aspx?src=${file.src}`
      // );
    },
    handleChange(file, fileList) {
      console.log(file);
      this.fileList = fileList.slice(-1);
      // let that = this;
      // var reader = new FileReader();
      // reader.readAsArrayBuffer(file.raw);
      // reader.onload = function (e) {
      //   const buffer = e.target.result; // 此时是arraybuffer类型
      //   mammoth
      //     .convertToHtml({ arrayBuffer: buffer })
      //     .then((result) => {
      //       console.log(result);
      //       that.vHtml = result.value;
      //     })
      //     .done();
      // };
    },
    dayin() {
      console.log("打印");
      printJS({
        printable: "printTest",
        type: "html",
        repeatTableHeader: true,
        scanStyles: true,
        maxWidth: "100%",
        targetStyles: ["*"],
        ignoreElements: ["no-print", "bc", "gb"],
      });
    },
    baocun() {
      this.is = !this.is;
      console.log(this.is);
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "2021年工匠精神奖申报"
        );
        this.is = !this.is;
      }, 1000);
    },
    clickXia() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "产学研创新示范企业-申报书",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Tokens"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 2,
              token: sessionStorage.getItem("Tokens"),
              type_name: "产学研创新示范企业-申报书",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                this.xinxi.type = 2;
                this.xinxi.id = this.user_split_id;
                this.xinxi.token = sessionStorage.getItem("Tokens");
                awards(this.xinxi).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                    // this.isShow = !this.isShow;
                    this.user_chile_details();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    clickXias() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "产学研创新示范企业",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Tokens"));
          upload_pdf(form).then((res) => {
            // console.log(res);
            window.open(
              `/common/2022/download_file?path=${res.data.url}&file_name=产学研创新示范企业`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    clickXiase() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "产学研创新示范企业",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Tokens"));
          upload_pdf(form).then((res) => {
            // console.log(res);
            window.open(
              `/common/2022/download_file?path=${res.data.url}&file_name=推荐信息`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    clickXia1() {
      this.isShow = !this.isShow;
    },
    fanhui() {
      this.num = 1;
    },
    fanhui1() {
      this.num = 2;
    },
    xiayibu1() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "产学研创新示范企业-推荐意见",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Tokens"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 3,
              token: sessionStorage.getItem("Tokens"),
              type_name: "产学研创新示范企业-推荐意见",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                this.xinxi1.type = 4;
                this.xinxi1.id = this.user_split_id;
                this.xinxi1.token = sessionStorage.getItem("Tokens");
                awards(this.xinxi1).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                    // this.isShow = !this.isShow;
                    this.user_chile_details();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    xiayibu1s() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Tokens"));
          upload_pdf(form).then((res) => {
            window.open(
              `https://cjh.xiaoxixing.cn/common/2022/download_file?path=${res.data.url}&file_name=推荐意见`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    xiayibu2() {
      this.num = 3;
    },
    xiayibu3() {
      this.num = 4;
    },
    wancheng() {
      this.obj.id = this.user_split_id;
      awards(this.obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    wancheng1() {
      if (!this.showss) {
        alert("请勾选");
        return;
      }

      this.$confirm("确认所有资料提交给中国产学研合作促进会办公室").then(
        (_) => {
          console.log(_);
          let obj = {
            token: sessionStorage.getItem("Tokens"),
            id: this.user_split_id,
          };
          submit_audit(obj)
            .then((res) => {
              if (res.data.code == 200) {
                this.centerDialogVisible = true;
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((_) => {});
        }
      );
    },
    xiaoxiabu8() {
      this.num = 3;
    },
    xiaoxiabu() {
      let params = {
        token: sessionStorage.getItem("Tokens"),
        id: this.id,
        type: 3,
        user_data: this.url,
      };
      craftsmanship_spirit(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    shangyibu() {
      this.num = 1;
    },
    daochus() {
      let p = {
        user_split_id: this.user_split_id,
        token: sessionStorage.getItem("Tokens"),
      };
      file_download(p).then((res) => {
        let blob = new Blob([res.data], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        const link = document.createElement("a"); // 创建a标签
        link.setAttribute(
          "download",
          sessionStorage.getItem("user_name") + "产学研创新示范企业"
        );
        link.href = url;
        link.click();
        URL.revokeObjectURL(url); // 释放
      });
    },
    ylan() {
      // window.open(
      //   `https://view.officeapps.live.com/op/view.aspx?src=${this.url}`
      // );
      window.open(this.url);
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.present {
  width: 100%;
  height: 100%;
  /* background-color: #fff; */
}
.main {
  width: 100%;
  height: calc(100% - 50px);
}
.shenMain {
  width: 97%;
  /* height: calc(100% - 100px); */
  height: auto;
  margin: 0 auto;
}
.shenMain table {
  width: 1300px;
  margin: 20px 0;
  border-collapse: collapse;
}
.shenMain tr {
  height: 75px;
}
.shenMain td {
  width: 150px;
  text-align: center;
  font-size: 24px;
}
caption {
  font-size: 28px;
  margin: 40px;
}
textarea {
  background: none;
  outline: none;
  border: 0px;
  width: 920px;
  height: 200px;
  font-size: 24px;
}
.tabls input {
  background: none;
  outline: none;
  border: 0px;
  font-size: 24px;
  /* width: 90px; */
}
.tabls_bom {
  width: 100%;
  display: flex;
  padding: 24px;
  box-sizing: border-box;
  justify-content: space-around;
}
.tabls_bom1 {
  width: 400px;
  display: flex;
  position: fixed;
  bottom: 20px;
  padding: 24px;
  left: 47%;
  box-sizing: border-box;
  justify-content: space-between;
}
.tabls_bom1 > div {
  width: 40%;
}
/* .tabls {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.tabls > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clickXia1 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
}
.biaoge {
  width: 98%;
  height: 90%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 20px auto;
  position: relative;
  /* justify-content: space-between; */
}
.biaoge > div:nth-child(1) {
  /* text-indent: 2em; */
  text-align: left;
}
.zunshou {
  height: 100%;
  text-indent: 2em;
  text-align: left;
  box-sizing: border-box;
  padding: 0 10px;
}
.zunshou div {
  margin-top: -50px;
}
.biaoge > div:nth-child(2) {
  display: flex;
  box-sizing: border-box;
  padding: 0 60px;
  margin-top: 250px;
  justify-content: space-between;
}
.biaoge > div:nth-child(2) > div {
  width: 350px;
}
.shangchuan {
  margin: 50px;
}
.shangchuan1 {
  margin: 50px;
}
.shangchuan > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.clickXia1_main {
  position: fixed;
  bottom: 20px;
  display: flex;
  width: 75%;
  justify-content: space-around;
  align-items: center;
}
.clickXia1_main1 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.clickXia1_main1 > div {
  margin-left: 50px;
}
.tables {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 10px;
}
#table {
  width: 100%;
  border-collapse: collapse;
}
#table input {
  background: none;
  outline: none;
  border: 0px;
  width: 150px;
}
#table td {
  width: 80px;
}
.box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 24px;
}
.box > div:nth-child(1) {
  width: 700px;
}
.box > div:nth-child(2) {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fengmian {
  width: 1200px;
  height: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 100px;
  padding-top: 300px;
  align-items: center;
}
.fengmian > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fengmian > div:nth-child(1) > div {
  font-size: 40px;
}
.fengmian > div:nth-child(2) > div {
  display: flex;
  align-items: center;
}
.fengmian > div:nth-child(2) > div > div:nth-child(1) {
  flex: 0 0 240px;
  font-size: 22px;
}

.fengmian > div:nth-child(2) > div > input {
  background: none;
  outline: none;
  border: 0px;
  font-size: 24px;
  border-bottom: 1px solid #999;
}

.clickXia1 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
}
.clickXia2 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: rgb(88, 79, 79);
  margin-top: 60px;
  margin-right: 10px;
}
.faren {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}
.yiqi {
  display: flex;
  height: calc(100vh - 170px);
  /* background-color: pink; */
}
.yiqi > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.yiqi > div:nth-child(1) > div {
  width: 180px;
  color: rgba(70, 57, 57, 0.83);
  cursor: pointer;
  font-size: 20px;
  margin: 15px 0;
}
.yiqi > div:nth-child(1) > div i {
  margin-right: 5px;
}
.yiqi > div:nth-child(1) {
  width: 15%;
  background-color: #fff;
  cursor: pointer;
  height: 100%;
}
.yiqi > div:nth-child(3) {
  box-sizing: border-box;
  padding: 20px;
}
.yiqi > div:nth-child(2) {
  overflow-y: scroll;
  height: 100%;
  flex: 1;
  margin: 0 10px;
  background-color: #fff;
}
.yiqi > div:nth-child(1) .colors {
  color: #409eff;
}
.feng_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.chea {
  margin: 0;
}
.zuihou {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.zuihou div {
  width: 800px;
  margin: 40px;
}
.tijiaos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tijiaos > div {
  font-size: 20px;
}
.tijiaos > div:nth-child(1) {
  font-size: 70px;
  font-weight: bold;
}
.tijiaos > div:last-child {
  width: 150px;
  border-radius: 10px;
  height: 40px;
  background: #409efe;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.tijiaos > div {
  margin: 10px 0;
}
.biaogesss {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  padding: 0 10px;
}
.biaoges {
  box-sizing: border-box;
  padding: 0 10px;
  position: absolute;
}
/* .feng_btn > div {
  padding: 10px;
} */
.xuanze {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 10px;
}
.xuanze > div {
  width: 280px;
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.jingkai {
  height: 100px;
  display: flex;
  box-sizing: border-box;
  padding: 10px 0;
  flex-direction: column;
  justify-content: space-around;
}
.chanxue {
  display: flex;
}
.chanxue > div {
  width: 450px;
}
.nian {
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
}
.biaoge {
  width: 98%;
  height: 90%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 20px auto;
  position: relative;
  /* justify-content: space-between; */
}
.bis > div:nth-child(1) {
  text-align: left;
  padding: 0 10px;
}
.bis > div:nth-child(2) {
  margin-top: 20px;
}
.bis > div:nth-child(3) {
  display: flex;
  box-sizing: border-box;
  padding: 0 60px;
  margin-top: 40px;
  justify-content: space-between;
}
.bis > div:nth-child(3) > div {
  width: 350px;
}
</style>