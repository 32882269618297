<template>
  <div class="awards">
    <div v-if="id == 1"></div>
    <div v-else-if="id == 2">
      <innovation></innovation>
    </div>
    <div v-else-if="id == 3">
      <present></present>
    </div>
    <div v-else-if="id == 4">
      <Promotion></Promotion>
    </div>
    <div v-else-if="id == 5">
      <Promotions></Promotions>
    </div>
    <div v-else-if="id == 6">
      <!-- <craftsman></craftsman> -->
    </div>
    <div v-else-if="id == 7">
      <achievement></achievement>
    </div>
    <div v-else-if="id == 8">
      <ferm></ferm>
    </div>
  </div>
</template>

<script>
import present from "./present";
import innovation from "./innovation";
import Promotion from "./Promotion";
import Promotions from "./Promotions";
import achievement from "./achievement";
import ferm from "./ferm";
import { user_chile_details } from "../axios/api";
export default {
  data() {
    return {
      id: "8",
    };
  },
  created() {
    // this.user_chile_details();
  },
  components: {
    present,
    innovation,
    Promotions,
    Promotion,
    achievement,
    // craftsman,
    ferm,
  },

  methods: {
    // user_chile_details() {
    //   let params = {
    //     token: sessionStorage.getItem("Token"),
    //   };
    //   user_chile_details(params).then((res) => {
    //     this.id = res.data.data.award_id;
    //   });
    // },
  },
};
</script>

<style scoped>
.awards {
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
  /* overflow: hidden; */
  /* background-color: #fff; */
}
.awards > div {
  width: 100%;
  height: 100%;
}
</style>